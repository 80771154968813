import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const LiquidFireworks = () => (
  <Layout
    title="Liquid Fireworks - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Liquid Fireworks</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Liquid Fireworks</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../fidget-spinner" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="57f33e28-92a1-4949-b4d3-3abd5f36e426"
                className="de-vid"
              ></Video>
              <Link to="../soap-boat" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Join 3M Scientist Jeff Payne as he uses nothing more than milk,
              dish soap, and a few other kitchen supplies to get the amazing
              effects of fireworks without using any fire at all.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              The structure of soap molecules enables them to remove dirt with
              ease, as well as move around oily particles. They consist of a
              hydrocarbon chain with an atom at the end. The hydrocarbon end is
              attracted to oil and repels water, whereas the other end attracts
              water. This can create all sorts of interesting effects in
              practice. Using the materials below, see if you can create a pop
              of color on your kitchen table.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Molecular interaction</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>Bowl or Saucer</li>
              <li>Milk</li>
              <li>Food Coloring</li>
              <li>Cotton Swab</li>
              <li>Liquid soap (dish soap or hand soap)</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>Fill the bowl about halfway full of milk.</li>
              <li>
                Drop 3-4 drops of food coloring near the center. You can use one
                color or several colors, just try not to mix them together.
              </li>
              <li>Cover one end of the cotton swab with liquid soap.</li>
              <li>
                Make a prediction. What is going to happen when the soap and the
                milk mix together?
              </li>
              <li>
                Take the cotton swab and gently place it in the center of the
                food coloring. Don't mix or stir, just watch what happens.
              </li>
              <li>
                Try moving the cotton swab to different parts of the bowl. Does
                it work differently on the edge than in the middle? Does the
                soap stop working eventually? Do the patterns look the same
                every time?
              </li>
              <li>
                Be sure to clean up when you are done. Pour the liquid mixture
                down the sink and rinse it down the drain. Wash the bowl or
                saucer. Throw away the cotton swab, and put the other materials
                back where you found them.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              Why did the food coloring push away from the cotton swab? Milk has
              fat and water in it, and fat does not dissolve in water. Soap is
              made up of special molecules that can interact with both fat and
              water, so when the soap starts to connect to the fat molecules in
              the milk, it allows them to dissolve in water and be moved around.
              The soap molecules are looking for fat molecules to join with, and
              as they move through the milk, they push the food coloring around.
              This eventually stops happening because all of the soap molecules
              find fat molecules to connect to, and they don't need to move
              around anymore. This is why dish soap is so effective at getting
              grease and oil off dishes. The soap molecules attach to grease
              molecules and allow them to be more easily dissolved in water, so
              they can wash right down the drain.
            </p>
            <p>
              If the fat in the milk is what makes this happen, do the results
              change if you try milk with different amounts of fat? For example,
              instead of whole milk, can you use skim milk, or 2%, or 1%? What
              about creamer? What else do you have that is made with milk and
              can be a liquid?
            </p>
            <p>
              Milk is great because the white background makes it easy to see
              the food coloring, but does it have to be milk, or can you use
              other liquids, or even combine other liquids? Keep in mind that
              not all liquids around the house are safe, so always check with an
              adult before using. Be especially careful if you want to use a
              liquid you wouldn't drink.
            </p>
            <p>
              Do you think there might be anything other than dish soap you
              could use to create the same effect? If you find options that work
              better or worse, can you come up with or research reasons why they
              are better or worse?
            </p>
            <p>
              Lastly, what about the cotton swab? See if you can think of other
              ways to add the dish soap. What if you add it in two or more spots
              at the same time?
            </p>
            <p>
              Bonus: food coloring works great as a visual display, but feel
              free to get creative. You can probably come up with other ways to
              show that the milk is moving.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS1.A - Structure and Properties of Matter
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>2-PS1-1.</em> Different kinds of matter exist and
                        many of them can be either solid or liquid (or gas).
                      </li>
                      <li>
                        <em>2-PS1-2.</em> Different properties are suited to
                        different purposes.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-1.</em> Matter of any type can be subdivided
                        into particles that are too small to see but even then,
                        the matter still exists and can be detected by other
                        means.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-1.</em> Substances are made of different
                        types of atoms, which combine with one another in
                        various ways. Atoms form molecules that range in size
                        from two to thousands of atoms.
                      </li>
                      <li>
                        <em>MS-PS1-4.</em> Gases and liquids are made of
                        molecules or inert atoms that are moving about relative
                        to each other. In a liquid, the molecules are constantly
                        in contact with others.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS1-1.</em> Each atom has a charged substructure
                        consisting of a nucleus, which is made of protons and
                        neutrons surrounded by electrons.
                      </li>
                      <li>
                        <em>HS-PS1-3.</em> The structure and interactions of
                        matter at the bulk scale are determined by electrical
                        forces within and between atoms.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    PS1.B - Chemical Reactions
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-4.</em> When two or more different substances
                        are mixed, a new substance with different properties may
                        be formed.
                      </li>
                      <li>
                        <em>5-PS1-2.</em> No matter what reaction or change in
                        properties occurs, the total weight of the substances
                        does not change.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-7.</em> Substances react chemically in
                        characteristic ways. In a chemical process, the atoms
                        that make up the original substances are regrouped into
                        different molecules and these new substances have
                        different properties from those of the reactants.
                      </li>
                      <li>
                        <em>MS-PS1-5.</em> The total number of each type of atom
                        is conserved, and thus the mass does not change.
                      </li>
                      <li>
                        <em>MS-PS1-6.</em> Some chemical reactions release
                        energy, others store energy.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS1-5.</em> Chemical processes, their rates, and
                        whether or not energy is stored or released can be
                        understood in terms of the collisions of molecules and
                        the rearrangements of atoms into new molecules, with
                        consequent changes in the sum of all bond energies in
                        the set of molecules that are matched by changes in
                        kinetic energy.
                      </li>
                      <li>
                        <em>HS-PS1-7.</em> The fact that atoms are conserved,
                        together with knowledge of the chemical properties of
                        the elements involved, can be used to describe and
                        predict chemical reactions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    PS2.A - Forces and Motion
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> Pushes and pulls can have different
                        strengths and directions.
                      </li>
                      <li>
                        <em>K-PS2-2.</em> Pushing or pulling on an object can
                        change the speed or direction of its motion and can
                        start or stop it.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Each force acts on one particular
                        object and has both strength and a direction.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-1.</em> For any pair of interacting objects,
                        the force exerted by the first object on the second
                        object is equal in strength to the force that the second
                        object exerts on the first, but in the opposite
                        direction.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS2-1.</em> Newton's second law accurately
                        predicts changes in the motion of macroscopic objects.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck4"></input>
                  <label className="label" for="chck4">
                    PS2.B - Types of Interaction
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> When objects touch or collide, they
                        push on one another and can change motion.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Objects in contact exert forces on
                        each other.
                      </li>
                      <li>
                        <em>5-PS2-1.</em> The gravitational force of Earth
                        acting on an object near Earth’s surface pulls that
                        object toward the planet’s center.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-4.</em> Gravitational forces are always
                        attractive. There is a gravitational force between any
                        two masses, but it is very small except when one or both
                        of the objects have large mass.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS2-6.</em> Attraction and repulsion between
                        electric charges at the atomic scale explain the
                        structure, properties, and transformations of matter, as
                        well as the contact forces between material objects.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="LiquidFireworks-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Jeff Payne</h3>
                <h4>Scientist, 3M</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="FidgetSpinner-Thumbnail.png" />
              <div className="card__content">
                <h3>Fidget Spinner</h3>
                <h4>
                  Your teacher might not love when you have one in class, but
                  the physics behind fidget spinners are truly head-spinning!
                </h4>
                <p>
                  Join 3M scientist Tesha R.-Alston Dampier as she shows you how
                  a spinning motion changes the way things move.
                </p>
              </div>
              <Link
                to="../fidget-spinner"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="SoapBoat-Thumbnail.png" />
              <div className="card__content">
                <h3>Soap Boat</h3>
                <h4>
                  Join 3M Researcher Vasav Shani as he introduces you to the
                  science of surface tension.
                </h4>
                <p>
                  Not only is it only important for many engineering and earth
                  science processes, it also makes blowing bubbles possible.
                </p>
              </div>
              <Link to="../soap-boat" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="HowDoWeBreathe-Thumbnail.png" />
              <div className="card__content">
                <h3>How Do We Breathe?</h3>
                <h4>How do our lungs work?</h4>
                <p>
                  Follow along as special guest Dakota Dozier, an offensive
                  lineman for the Minnesota Vikings, makes a model to show how
                  air flows in and out of the lungs with ease.
                </p>
              </div>
              <Link
                to="../how-do-we-breathe"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default LiquidFireworks;
